<script setup lang="ts">
// NPM
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, email, required, url } from "@vuelidate/validators";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

// Components
import {
  BaseAvatar,
  BaseButton,
  BaseIcon,
  FieldInput,
  BaseLoader,
  FieldPhoneNumber,
  FieldSelect,
  FieldCheckbox,
  FieldTextarea,
} from "@magma-app/magma-lapilli";
import HelpeeSignUp from "@/components/sections/HelpeeSignUp.vue";
import TheFooter from "@/components/shell/TheFooter.vue";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";

// Composables & Stores
import { useConvertHexToRgb } from "@/composables/useConvertHexToRgb";
import { useAuthStore } from "@/stores/auth";
import { watchEffect } from "vue";
import type { IPublicOrganizationData } from "@/types/IPublicOrganizationData";
const { hexColor, rgbColor } = useConvertHexToRgb();
const authStore = useAuthStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t, locale } = useI18n({ useScope: "global" });
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const campaignsView = ref(false);
const containerWidth = ref(0);
const detailsStep = ref(false);
const formStep = ref(true);
const phoneVerification = ref(false);
const criteriaStep = ref(false);
const initialized = ref(false);
const lastPath = ref(
  JSON.stringify(router.options.history.state.back).includes(
    "/helpee-campaigns/"
  )
);
const redirectionStep = ref(false);
const root = ref<HTMLInputElement>();
const widget = ref(false);
const isLoading = ref(false);

const formData = reactive({
  firstname: authStore.helpeePostSignUpData?.firstname || "",
  lastname: authStore.helpeePostSignUpData?.lastname || "",
  phoneNumber: authStore.helpeePostSignUpData?.phoneNumber || "",
  criteria: null as null | string,
  postSignUp: {
    chosenTouchpoints: [] as number[],
    criteria: {} as { [key: string]: string | string[] },
    acceptEmail: true,
    acceptPhone: true,
    code: "",
    touchpoints: {
      line: "",
      phone: "",
      whatsapp: "",
      calendly: "",
      discord: "",
      linkedin: "",
      weChat: "",
      youtube: "",
      tiktok: "",
      telegram: "",
      snapchat: "",
      "X (Twitter)": "",
      messenger: "",
      instagram: "",
      facebook: "",
    } as { [key: string]: string },
  },
});

const codeSent = ref(true);
const timerBeforeResend = ref(30);
const codeError = ref(false);

// Form validation
const formRules = {
  phone: "phoneNumber",
  line: "phoneNumber",
  whatsapp: "phoneNumber",
  calendly: "pseudo",
  discord: "pseudo",
  linkedin: "pseudo",
  weChat: "pseudo",
  youtube: "pseudo",
  tiktok: "pseudo",
  telegram: "pseudo",
  snapchat: "pseudo",
  "X (Twitter)": "pseudo",
  messenger: "pseudo",
  instagram: "pseudo",
  facebook: "pseudo",
} as { [key: string]: string };

const activateTouchPoint = (touchPointId: number) => {
  if (!formData.postSignUp.chosenTouchpoints.includes(touchPointId)) {
    formData.postSignUp.chosenTouchpoints.push(touchPointId);
    setTimeout(() => {
      const input = root.value?.querySelector(
        `#touchpoint-${touchPointId}`
      ) as HTMLInputElement;

      if (input) {
        input.focus();
      }
    }, 10);
  } else {
    formData.postSignUp.chosenTouchpoints =
      formData.postSignUp.chosenTouchpoints.filter((id) => id !== touchPointId);
  }
};

const finalRules = Object.keys(formRules).reduce((acc: any, key) => {
  if (formRules[key] === "phoneNumber") {
    acc[key] = {
      validPhoneNumber: withI18nMessage(
        (value: string) => {
          if (value != "") {
            return isValidPhoneNumber(value);
          } else {
            return true;
          }
        },
        {
          messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
        }
      ),
    };
  } else if (formRules[key] === "pseudo") {
    acc[key] = {
      url: withI18nMessage(url, {
        messagePath: () => t("validation.urlValidation"),
      }),
    };
  }

  return acc;
}, {});

const v$ = useVuelidate(
  {
    firstname: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    lastname: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    criteria: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    phoneNumber: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
      validPhoneNumber: withI18nMessage(
        (value: string) => isValidPhoneNumber(value),
        {
          messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
        }
      ),
    },
    postSignUp: {
      code: {
        required: withI18nMessage(required, {
          messagePath: () => t("validation.value"),
        }),
      },
      touchpoints: {
        mail: {
          email: withI18nMessage(email, {
            messagePath: () => t("validation.invalidEmail"),
          }),
        },
        ...finalRules,
      },
    },
  },
  formData
);

// Computed
const backgroundColor = computed(() => {
  if (
    authStore.helpeeSignUpData?.headerType === "community" &&
    !campaignsView.value
  ) {
    return "gradient__community";
  } else {
    return "bg-background-default";
  }
});

const currentCampaign = computed(() => {
  if (
    authStore.helpeePostSignUpData?.needPickUpCampaign &&
    authStore.helpeePostSignUpData?.campaigns
  ) {
    return authStore.helpeePostSignUpData.campaigns.find(
      (currentCampaign) => currentCampaign.uuid === route?.params.campaignUuid
    );
  } else if (
    !authStore.helpeePostSignUpData?.needPickUpCampaign &&
    authStore.helpeePostSignUpData?.campaignUuid
  ) {
    return authStore.helpeePostSignUpData;
  } else {
    return undefined;
  }
});

const desiredTouchpoints = computed(() => {
  return postSignUpAvailableTouchpoints.value.filter((touchpoint) => {
    return formData.postSignUp.chosenTouchpoints.includes(touchpoint.value);
  });
});

const helpersProfilePicture = computed(() => {
  const enoughHelpersPics =
    authStore.helpeeSignUpData?.helpersProfilePicture &&
    authStore.helpeeSignUpData?.helpersProfilePicture?.length > 4
      ? true
      : false;

  return [
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[2]
        : "/img/team/hugo.png",
      position: "top-11 left-[15%]",
      size: "h-12 w-12",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[3]
        : "/img/team/joris.png",
      position: "bottom-4 left-[20%]",
      size: "h-12 w-12",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[0]
        : "/img/team/marine.png",
      position: "bottom-1/4 right-[40%]",
      size: "h-12 w-12",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[4]
        : "/img/team/nada.png",
      position: "top-6 right-[30%]",
      size: "h-12 w-12",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[5]
        : "/img/team/edouard.png",
      position: "bottom-4 right-[15%]",
      size: "h-12 w-12",
    },
    {
      blur: true,
      picture: "/img/avatars/blur_1.png",
      position: "top-1/3 right-[55%]",
      size: "h-8 w-8",
    },
    {
      blur: true,
      picture: "/img/avatars/blur_2.png",
      position: "top-[40%] right-7",
      size: "h-8 w-8",
    },
    {
      blur: true,
      picture: "/img/avatars/blur_3.png",
      position: "bottom-1/4 left-7",
      size: "h-8 w-8",
    },
  ];
});

const optionalCampaignCriteria = computed(() => {
  if (currentCampaign.value) {
    return currentCampaign.value.criteria?.filter((criteria) => {
      return criteria.id !== strongCriteria.value?.id;
    });
  } else {
    return undefined;
  }
});

const haveSelectedCriteria = computed(() => {
  const userCriteria = Object.keys(formData.postSignUp.criteria)
    .map((criteriaId) => {
      const criteriaValue = formData.postSignUp.criteria[criteriaId];
      const criteriaAnswerKey = Array.isArray(criteriaValue)
        ? "answerKeys"
        : "answerKey";

      if (criteriaValue?.length) {
        return {
          criteriaId: parseInt(criteriaId),
          [criteriaAnswerKey]: criteriaValue,
        };
      } else {
        return null;
      }
    })
    .filter((criterium) => criterium != null) as {
    criteriaId: number;
    answerKey?: string;
    answerKeys?: string[];
  }[];

  if (userCriteria.length === 0) {
    return false;
  } else {
    return true;
  }
});

const postSignUpAvailableTouchpoints = computed(() => {
  return (
    currentCampaign.value?.touchPoints?.map(
      (availableTouchpoints: {
        icon: string;
        name: string;
        id: number;
        type: string;
      }) => ({
        label: availableTouchpoints.name,
        icon: availableTouchpoints.icon,
        value: availableTouchpoints.id,
        type: availableTouchpoints.type,
      })
    ) || []
  );
});

const strongCriteria = computed(() => {
  if (currentCampaign.value && currentCampaign.value.criteria) {
    const strongCriteria = currentCampaign.value.criteria.find(
      (strongCriteria) => {
        return strongCriteria.weight === "strong";
      }
    );

    if (strongCriteria) {
      return strongCriteria;
    }

    const mediumCriteria = currentCampaign.value.criteria.find(
      (strongCriteria) => {
        return strongCriteria.weight === "medium";
      }
    );

    if (mediumCriteria) {
      return mediumCriteria;
    }

    const lowCriteria = currentCampaign.value.criteria.find(
      (strongCriteria) => {
        return strongCriteria.weight === "low";
      }
    );

    if (lowCriteria) {
      return lowCriteria;
    }

    return currentCampaign.value.criteria[0];
  } else {
    return undefined;
  }
});

// Watchers
watch(
  () => formData.postSignUp.chosenTouchpoints,
  (newChosenTouchpoints) => {
    // Empty formdata value of all touchpoints that are not in the new chosen touchpoints
    for (const touchpoint of postSignUpAvailableTouchpoints.value) {
      if (!newChosenTouchpoints.includes(touchpoint.value)) {
        formData.postSignUp.touchpoints[touchpoint.label] = "";

        // Remove the touchpoint label from watchFields
        const index = watchFields.indexOf(touchpoint.label);
        if (index !== -1) {
          watchFields.splice(index, 1);
        }
      } else {
        // If the touchpoint is selected, add its label to watchFields
        if (!watchFields.includes(touchpoint.label)) {
          watchFields.push(touchpoint.label);
        }
      }
    }
  }
);

watch(
  () => formData.firstname,
  async () => await v$.value.firstname.$validate()
);

watch(
  () => formData.lastname,
  async () => await v$.value.lastname.$validate()
);

watch(
  () => formData.phoneNumber,
  async (newValue) => {
    await v$.value.phoneNumber.$validate();
  }
);

const watchFields: string[] = [];

for (const field of watchFields) {
  watch(
    () => formData.postSignUp.touchpoints[field],
    async () => await v$.value.postSignUp.touchpoints[field].$validate()
  );
}

watchEffect(() => {
  if (
    phoneVerification.value &&
    codeSent.value &&
    timerBeforeResend.value > 0
  ) {
    setTimeout(() => {
      timerBeforeResend.value--;
    }, 1000);
  }

  if (timerBeforeResend.value === 0) {
    codeSent.value = false;
  }
});

// Lifecycle hooks
onMounted(async () => {
  if (lastPath.value) {
    campaignsView.value = true;

    await computeContainerWidth();
  }

  if (route?.query.widget) {
    widget.value = true;
  }

  if (route?.params.campaignUuid) {
    const payload = {
      campaignUuid: route?.params.campaignUuid as string,
    };

    if (payload) {
      await authStore.getHelpeeSignUpData(payload);
    }

    // Check if form value is in query params
    const params = route.query;

    if (params.firstname) {
      formData.firstname = params.firstname as string;
    }

    if (params.lastname) {
      formData.lastname = params.lastname as string;
    }

    if (params.phoneNumber) {
      const url = window.location.href;
      const start = url.indexOf("phoneNumber=") + 1;
      const sub = url.substring(start);
      const phoneNumber = sub.split("&")[0].split("=")[1].replace("%2B", "+");

      if (phoneNumber.startsWith("+") && phoneNumber.length > 1) {
        const tel = parsePhoneNumber(phoneNumber);
        formData.phoneNumber = tel.formatNational();
      } else {
        formData.phoneNumber = phoneNumber;
      }
    }
  }

  generateFormDataCriteria();

  if (authStore.helpeeSignUpData) {
    locale.value = authStore.helpeeSignUpData.language;
  }

  if (authStore.helpeeSignUpData?.primaryColor) {
    hexColor.value = authStore.helpeeSignUpData.primaryColor;
  }

  initialized.value = true;

  await computeContainerWidth();

  window.addEventListener("resize", computeContainerWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", computeContainerWidth);
});

// Event listeners
const onValidateStrongCriteriaEvent = () => {
  v$.value.criteria.$validate();
};

// Methods
const changeView = async (data: any) => {
  if (data.firstname) {
    formData.firstname = data?.firstname;
  }

  if (data.lastname) {
    formData.lastname = data?.lastname;
  }

  if (data.phoneNumber) {
    formData.phoneNumber = data?.phoneNumber;
  }

  campaignsView.value = true;

  await computeContainerWidth();
};

const completeProfile = async () => {
  isLoading.value = true;
  if (authStore.helpeePostSignUpData?.needPhoneNumberVerification) {
    v$.value.postSignUp.$touch();
  }

  if (
    (authStore.helpeePostSignUpData?.needPhoneNumberVerification &&
      (await v$.value.postSignUp.$validate())) ||
    !authStore.helpeePostSignUpData?.needPhoneNumberVerification
  ) {
    // Format criteria and touchpoints
    const userCriteria = Object.keys(formData.postSignUp.criteria)
      .map((criteriaId) => {
        const criteriaValue = formData.postSignUp.criteria[criteriaId];
        const criteriaAnswerKey = Array.isArray(criteriaValue)
          ? "answerKeys"
          : "answerKey";

        if (criteriaValue?.length) {
          return {
            criteriaId: parseInt(criteriaId),
            [criteriaAnswerKey]: criteriaValue,
          };
        } else {
          return null;
        }
      })
      .filter((criterium) => criterium != null) as {
      criteriaId: number;
      answerKey?: string;
      answerKeys?: string[];
    }[];

    if (currentCampaign.value) {
      const payload = {
        campaignUuid: route?.params.campaignUuid as string,
        helpeeId: authStore.helpeePostSignUpData?.helpeeId,
        organizationId: authStore.helpeePostSignUpData?.organizationId,
        userCriteria,
      };

      await authStore.addOptionalInfoSignUpHelpee(payload);
    }

    isLoading.value = false;
    detailsStep.value = false;
    phoneVerification.value = false;
    criteriaStep.value = false;
    redirectionStep.value = true;

    // Track add details event
    AnalyticsHelper.trackEvent("user_complete_details");

    setTimeout(() => {
      if (authStore.helpeePostCompleteSignUpData?.helpeeSignupRedirectUrl) {
        window.location.href =
          authStore.helpeePostCompleteSignUpData?.helpeeSignupRedirectUrl;
      }
    }, 2000);
  }
};

const computeContainerWidth = async () => {
  await nextTick();

  const container = root.value?.querySelector(".js-container") as HTMLElement;

  if (container) {
    containerWidth.value = container.offsetWidth || 0;
  }
};

const generateFormDataCriteria = () => {
  if (currentCampaign.value) {
    formData.postSignUp.criteria = currentCampaign.value.criteria?.reduce(
      (accumulator: { [key: string]: string | string[] }, criteria) => {
        accumulator[criteria.id] = criteria.type === "singleSelect" ? "" : [];

        return accumulator;
      },
      {}
    ) as { [key: string]: string | string[] };
  }
};

const getCriteriaOptions = () => {
  if (currentCampaign.value) {
    return strongCriteria.value?.options.map(
      (criteriaOption: { key: string; value: string }) => ({
        label: criteriaOption.value,
        value: criteriaOption.key,
      })
    );
  } else {
    return [];
  }
};

const getPostSignUpCriteriaOptions = (criteriaId: number) => {
  const campaignCriteria = optionalCampaignCriteria.value?.find(
    (criteria) => criteria.id === criteriaId
  );

  if (campaignCriteria) {
    return campaignCriteria.options.map(
      (criteriaOption: { key: string; value: string }) => ({
        label: criteriaOption.value,
        value: criteriaOption.key,
      })
    );
  } else {
    return [];
  }
};

const goBack = () => {
  if (formStep.value) {
    // formStep.value = false;
    // campaignsView.value = false;
    return;
  } else if (phoneVerification.value) {
    phoneVerification.value = false;
    formStep.value = true;
    codeError.value = false;
  } else if (criteriaStep.value) {
    criteriaStep.value = false;
    formStep.value = true;
  }
};

const sendOptionalCriteriaEvent = async (criteriaIndex: number) => {
  // Wait for formData to be updated before comparing to form data saved
  await nextTick();

  if (criteriaIndex === 0) {
    AnalyticsHelper.trackEvent("second_criteria_filled");
  } else if (criteriaIndex === 1) {
    AnalyticsHelper.trackEvent("third_criteria_filled");
  }
};

const sendStrongCriteriaEvent = async () => {
  // Wait for formData to be updated before comparing to form data saved
  await nextTick();

  AnalyticsHelper.trackEvent("first_criteria_filled");
};

const sendTouchpointEvent = async (touchPointLabel: string) => {
  // Wait for formData to be updated before comparing to form data saved
  await nextTick();

  if (formData.postSignUp.touchpoints[touchPointLabel]?.length > 0) {
    AnalyticsHelper.trackEvent(`${touchPointLabel}_touchpoint_filled`);
  }
};

const goBackToForm = () => {
  phoneVerification.value = false;
  formStep.value = true;
};

const signUp = async () => {
  try {
    v$.value.firstname.$touch();
    v$.value.lastname.$touch();
    v$.value.criteria.$touch();
    v$.value.phoneNumber.$touch();

    if (
      (await v$.value.firstname.$validate()) &&
      (await v$.value.lastname.$validate()) &&
      (await v$.value.criteria.$validate()) &&
      (await v$.value.phoneNumber.$validate()) &&
      currentCampaign.value &&
      strongCriteria.value
    ) {
      isLoading.value = true;

      const objectKey =
        typeof formData.criteria === "object" ? "answerKeys" : "answerKey";

      const payload = {
        campaignUuid: route?.params.campaignUuid as string,
        firstname: formData.firstname,
        helpeeId: authStore.helpeePostSignUpData?.helpeeId,
        lastname: formData.lastname,
        organizationId: authStore.helpeePostSignUpData?.organizationId,
        phoneNumber: formData.phoneNumber,
        strongUserCriteria: {
          criteriaId: strongCriteria.value.id,
          [objectKey]: formData.criteria,
        },
      };

      await authStore.completeSignUpHelpee(payload);

      generateFormDataCriteria();

      isLoading.value = false;
      formStep.value = false;
      detailsStep.value = false;

      if (authStore.helpeePostMatchData?.needPhoneNumberVerification) {
        phoneVerification.value = true;
      } else {
        criteriaStep.value = true;
      }

      // Track fill form event
      AnalyticsHelper.trackEvent("user_complete_information");
    }
  } catch (error: any) {
    if (error.response?.data?.message.includes("already exist")) {
      formStep.value = false;
      redirectionStep.value = true;
    }
  }
};

const resendPhoneCode = async () => {
  if (authStore.helpeePostSignUpData?.helpeeId) {
    await authStore.sendCodeRequest({
      phoneNumber: formData.phoneNumber,
      helpeeId: authStore.helpeePostSignUpData?.helpeeId,
    });

    codeSent.value = true;
    timerBeforeResend.value = 30;
  }
};

const validateCode = async () => {
  isLoading.value = true;
  if (authStore.helpeePostSignUpData?.helpeeId) {
    try {
      await authStore.validateCode({
        code: formData.postSignUp.code,
        helpeeId: authStore.helpeePostSignUpData?.helpeeId,
      });

      if (!authStore.codeValidated?.error) {
        redirectionStep.value = false;
        phoneVerification.value = false;
        criteriaStep.value = true;
      } else {
        codeError.value = false;
      }
    } catch (e) {
      codeError.value = true;
    }
  }
  isLoading.value = false;
};
</script>

<template>
  <div ref="root" data-cy="e2e-main-div" class="flex flex-col">
    <div
      v-if="initialized"
      class="scrollbar-hide flex flex-1 flex-col"
      :class="[
        backgroundColor,
        widget
          ? 'relative shadow-[0px_20px_24px_-4px_rgba(28,31,39,0.08),0px_8px_8px_-4px_rgba(28,31,39,0.03)]'
          : 'mx-auto w-full md:relative md:mt-20 md:h-[650px] md:w-[654px] md:rounded-[15px] md:shadow-[0px_20px_24px_-4px_rgba(28,31,39,0.08),0px_8px_8px_-4px_rgba(28,31,39,0.03)]',
        ,
        !widget && campaignsView
          ? 'overflow-y-scroll md:max-h-[650px] '
          : !widget
            ? 'max-h-[650px] '
            : undefined,
        {
          'h-full': !campaignsView,
        },
      ]"
    >
      <div
        :class="[
          'flex h-16 items-center p-4 md:px-10',
          {
            'border-b border-b-border-subtle':
              campaignsView ||
              authStore.helpeeSignUpData?.headerType === 'image',
          },
        ]"
      >
        <BaseAvatar
          :src="authStore.helpeeSignUpData?.logoUrl"
          :alt="'logo ' + authStore.helpeeSignUpData?.organizationName"
          :fallback="authStore.helpeeSignUpData?.name"
        />

        <h1
          class="max-w-[300px] truncate px-2 text-mgm-txt-sm font-medium text-foreground-emphasis lg:max-w-[300px]"
        >
          {{ authStore.helpeeSignUpData?.name }}
        </h1>
      </div>

      <HelpeeSignUp v-if="!campaignsView" @success="changeView" />

      <div
        v-if="campaignsView"
        ref="root"
        class="js-container flex flex-1 flex-col"
        :class="[
          redirectionStep
            ? 'md:pb-6'
            : widget
              ? containerWidth < 600
                ? 'px-6 pb-6 pt-6'
                : 'px-10 pb-16 pt-6'
              : 'px-6 pt-6 pb-6 md:px-10',
        ]"
      >
        <div v-if="!redirectionStep" class="mb-4 flex">
          <BaseIcon color="#5F6C85" icon="chevron-left" @click="goBack" />

          <span
            class="ml-2 block pr-1 text-mgm-txt-xs font-medium text-foreground-emphasis"
            >{{ $t("generic.step") + " " + (criteriaStep ? "2" : "1") }}</span
          >
          <span
            class="block text-mgm-txt-xs font-medium text-foreground-subtle"
            >{{ `/ 2` }}</span
          >
        </div>

        <div
          v-if="formStep && !phoneVerification"
          class="relative h-full flex-1"
        >
          <h3 class="text-mgm-txt-lg font-medium text-foreground-emphasis">
            {{ $t("helpeeCampaignSignUpView.yourInfo") }}
          </h3>

          <FieldInput
            id="firstname"
            v-model="formData.firstname"
            data-cy="firstname"
            class="mt-4"
            :locale="$i18n.locale"
            :label="$t('generic.firstNameLabel')"
            placeholder="John"
            required
            :validation="v$.firstname"
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
          />

          <FieldInput
            id="lastname"
            v-model="formData.lastname"
            data-cy="lastname"
            class="mt-4"
            :locale="$i18n.locale"
            :label="$t('generic.lastNameLabel')"
            placeholder="Doe"
            required
            :validation="v$.lastname"
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
          />

          <FieldPhoneNumber
            id="phone"
            :model-value="formData.phoneNumber"
            @update:model-value="
              (value) => {
                if (value) {
                  formData.phoneNumber = value;
                }
              }
            "
            data-cy="phone"
            class="mt-4"
            :locale="$i18n.locale"
            :label="$t('generic.phone')"
            required
            :validation="v$.phoneNumber"
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
            :description="$t('helpeeCampaignSignUpView.sendSMS')"
          />

          <FieldSelect
            v-if="strongCriteria"
            :id="strongCriteria.id"
            v-model="formData.criteria"
            data-cy="criteria"
            class="mt-4"
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
            :label="strongCriteria.label"
            :options="getCriteriaOptions()"
            :multiple="strongCriteria.type === 'multiSelect'"
            :validation="v$.criteria"
            required
            @change="sendStrongCriteriaEvent()"
            @blur="onValidateStrongCriteriaEvent()"
          />

          <BaseButton
            data-cy="signup-next"
            class="mt-8 w-full"
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
            :disabled="
              v$.firstname.$error ||
              v$.lastname.$error ||
              v$.phoneNumber.$error ||
              v$.criteria.$error ||
              isLoading
            "
            :text="$t('generic.next')"
            size="md"
            @click="signUp"
          />
        </div>

        <div v-if="phoneVerification" class="relative h-full flex-1">
          <h3 class="text-mgm-txt-lg font-medium text-foreground-emphasis mb-4">
            {{ $t("helpeeCampaignSignUpView.phoneVerification") }}
          </h3>

          <p class="text-foreground-default text-mgm-txt-sm mb-4">
            {{ $t("helpeeCampaignSignUpView.code.text") }}
            {{ formData.phoneNumber }} (<span
              role="button"
              class="underline"
              @click="goBackToForm"
              >{{ $t("helpeeCampaignSignUpView.code.modifyNumber") }}</span
            >)
          </p>

          <FieldInput
            id="code"
            type="number"
            autocomplete="one-time-code"
            max-length="6"
            min-length="6"
            v-model="formData.postSignUp.code"
            data-cy="code"
            class="mt-4"
            :locale="$i18n.locale"
            :label="$t('helpeeCampaignSignUpView.codeLabel')"
            required
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
            :validation-disabled="true"
          />

          <span
            v-if="codeError"
            class="text-mgm-txt-xs text-background-danger-emphasis"
            >{{ $t("helpeeCampaignSignUpView.codeWrong") }}</span
          >
          <div class="flex items-center flex-col mt-4">
            <p class="text-foreground-default text-mgm-txt-sm mb-1">
              {{ $t("helpeeCampaignSignUpView.noReceive") }}
            </p>
            <p
              v-if="codeSent"
              class="text-foreground-default text-mgm-txt-sm mb-1 font-medium"
            >
              {{ $t("helpeeCampaignSignUpView.code.resend") }}
              {{ timerBeforeResend }}
              {{ $t("helpeeCampaignSignUpView.code.seconds") }}
            </p>
            <button
              v-else
              class="text-foreground-brand-default text-mgm-txt-sm flex items-center"
              @click="resendPhoneCode"
            >
              <BaseIcon
                icon="refresh-cw"
                class="mr-2 -scale-x-100"
                color="rgb(68 76 231)"
              />
              <span class="underline">{{
                $t("helpeeCampaignSignUpView.resendCode")
              }}</span>
            </button>
          </div>

          <BaseButton
            data-cy="signup-next"
            class="mt-8 w-full"
            :disabled="
              formData.postSignUp.code === '' ||
              formData.postSignUp.code.length !== 6 ||
              isLoading
            "
            :text="$t('generic.next')"
            size="md"
            @click="validateCode"
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
          />
        </div>

        <div v-if="criteriaStep">
          <h3 class="text-mgm-txt-lg font-medium text-foreground-emphasis mb-4">
            {{ $t("helpeeCampaignSignUpView.moreDetails") }}
          </h3>

          <FieldSelect
            v-for="(
              optionalCampaignCriteriaItem, optionalCampaignCriteriaIndex
            ) in optionalCampaignCriteria"
            :id="optionalCampaignCriteriaItem.id"
            :key="optionalCampaignCriteriaIndex"
            v-model="
              formData.postSignUp.criteria[optionalCampaignCriteriaItem.id]
            "
            class="mt-4"
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
            :label="optionalCampaignCriteriaItem.label"
            :options="
              getPostSignUpCriteriaOptions(optionalCampaignCriteriaItem.id)
            "
            :multiple="optionalCampaignCriteriaItem.type === 'multiSelect'"
            @change="sendOptionalCriteriaEvent(optionalCampaignCriteriaIndex)"
          />

          <BaseButton
            data-cy="signup-next"
            class="mt-8 w-full"
            :disabled="!haveSelectedCriteria || isLoading"
            :text="$t('generic.next')"
            size="md"
            @click="completeProfile"
            :custom-color="authStore.helpeeSignUpData?.primaryColor"
            :custom-rgb-color="
              rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
            "
          />
        </div>

        <div
          v-else-if="redirectionStep"
          data-cy="signup-confirmation"
          class="relative h-full w-full flex-1 text-center"
        >
          <div
            :class="[
              `relative h-[200px] bg-[url('/img/illustration_redirection_background.svg')] bg-cover bg-center md:rounded-t-[15px]`,
              {
                'md:rounded-t-[15px]': containerWidth >= 600,
              },
            ]"
          >
            <div
              v-for="(helper, helperIndex) in helpersProfilePicture"
              :key="helperIndex"
              :class="['absolute', helper.position]"
            >
              <div
                :class="[
                  'overflow-hidden rounded-full',
                  helper.size,
                  { 'border-2 border-border-on-emphasis': !helper.blur },
                ]"
              >
                <img class="h-full w-full object-cover" :src="helper.picture" />
              </div>
            </div>
          </div>

          <h3
            class="mx-auto mt-10 max-w-xs text-mgm-txt-xl font-semibold text-foreground-emphasis"
          >
            {{ $t("helpeeCampaignSignUpView.redirection.title") }}
          </h3>

          <p
            class="mx-auto mt-4 max-w-xs text-mgm-txt-sm font-normal text-foreground-default"
          >
            {{ $t("helpeeCampaignSignUpView.redirection.description") }}
          </p>
        </div>
      </div>

      <TheFooter
        class="bg-background-default md:sticky md:bottom-0 md:left-0 md:right-0 md:mt-0"
        :class="{
          'md:static': !widget && campaignsView,
          'pt-4': detailsStep,
        }"
      />
    </div>

    <BaseLoader v-else class="min-h-screen" />
  </div>
</template>

<style scoped>
.gradient__community {
  background: linear-gradient(180deg, rgba(68, 76, 231, 0.15) 0%, #ffffff 40%);
}
</style>
