<script setup lang="ts">
// NPM
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Modal } from "@magma-app/magma-lapilli";

// Components
import {
  BaseBadge,
  BaseEmptyState,
  FieldTabs,
  BaseCard,
  BaseButton,
} from "@magma-app/magma-lapilli";
import BaseRelation from "@/components/bases/BaseRelation.vue";
import TheSidebar from "@/components/shell/TheSidebar.vue";

// Types
import type { IRelation } from "@/types/IRelation";

// Stores
import { useRelationsStore } from "@/stores/relations";
import { useUserStore } from "@/stores/user";
import { useChallengesStore } from "@/stores/challenges";
import { useMq } from "vue3-mq";
import type { IChallenge } from "@/types/IChallenge";
import { useCommonStore } from "@/stores/common";
import { useChallenges } from "@/api/challenges/use-challenges";
import { useStartChallenge } from "@/api/challenges/use-start-challenge";
const relationsStore = useRelationsStore();
const userStore = useUserStore();
const challengesStore = useChallengesStore();
const commonStore = useCommonStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t } = useI18n({ useScope: "global" });
const mq = useMq();

// Data
const tabs = [
  {
    label: t("generic.toProcess"),
    value: "toProcess",
  },
  {
    label: t("generic.ongoing"),
    value: "ongoing",
  },
  {
    label: t("generic.finished"),
    value: "finished",
  },
];

// Computed
const hasRelations = computed(() => {
  const relations = relationsStore.relations;

  return Boolean(
    relations.finished?.length ||
      relations.ongoing?.length ||
      relations.toProcess?.length
  );
});

const relations = computed(() => {
  if (currentTab.value === "toProcess") {
    return relationsStore.relations.toProcess
      .slice()
      .sort((a, b) => b.daysLeft - a.daysLeft);
  } else if (currentTab.value === "ongoing") {
    return relationsStore.relations.ongoing;
  } else if (currentTab.value === "finished") {
    return relationsStore.relations.finished;
  } else {
    return [];
  }
});

const statuses: ("finished" | "ongoing" | "toProcess")[] = [
  "finished",
  "ongoing",
  "toProcess",
];

// Reactive variables
const challengeId = ref<number | null>(null);
const challengeOpen = ref(false);
const { data: challenges, isLoading } = useChallenges();
const { mutate: startChallenge } = useStartChallenge();

// Reactive variables
const modal = ref<number | null>(null);
const currentModalStep = ref<"start" | "confirm" | "end">("start");

// Computed
const challengeDetails = computed(() => {
  return challengesStore.availableChallenges.find((challenge) => {
    return challenge.challengeId === challengeId.value;
  });
});

const onChallengeClick = (challenge: IChallenge) => {
  if (!mq.lgPlus && challenge.challengeId) {
    challengeOpen.value = true;
    challengesStore.setChallengeDetails(true);
    challengeId.value = challenge.challengeId;
    window.scrollTo(0, 0);
  } else if (mq.lgPlus) {
    commonStore.setManageChallengesRewardsModal({
      description: challenge.description,
      open: true,
      item: challenge,
      role: "challenge",
      title: challenge.name,
    });
  }
};

// Reactive variables
const initialized = ref(false);
const currentTab = ref<"finished" | "ongoing" | "toProcess">("toProcess");

// Lifecycle hooks
onBeforeMount(async () => {
  await relationsStore.getRelations();

  if (route?.params.relationId) {
    for (const status of statuses) {
      if (findRelationStatus(status)) {
        currentTab.value = status;
        break;
      }
    }
  }

  initialized.value = true;
});

// Watchers
watch(route, async (newRoute) => {
  if (newRoute?.params.relationId) {
    for (const status of statuses) {
      if (findRelationStatus(status)) {
        currentTab.value = status;
        break;
      }
    }
  }
});

// Event Listeners
const onRelationClick = async (relation: IRelation) => {
  // Mark relation as read
  if (!relation.isRead) {
    await relationsStore.markRelationMomentAs({
      relationMomentId: relation.relationMomentId,
      role: "read",
    });

    await relationsStore.getRelations();
  }

  // Go to relation
  router.push({
    name: "helper-relation-overview",
    params: {
      relationId: relation.relationId,
    },
  });
};

const onCloseChallengeDetails = () => {
  challengesStore.setChallengeDetails(false);
  challengeOpen.value = false;
  challengeId.value = null;
};

// Methods
const findRelationStatus = (status: "finished" | "ongoing" | "toProcess") => {
  return relationsStore.relations[status].some(
    (relation: IRelation) =>
      relation.relationId === parseInt(route?.params.relationId as string)
  );
};
</script>

<template>
  <div class="flex bg-background-subtle" data-cy="relations-layout">
    <TheSidebar />

    <div
      class="w-full flex-col bg-background-default pt-2 lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:border-b-transparent lg:pt-8 2xl:ml-64"
      :class="[
        $route?.params.relationId ? 'hidden lg:flex' : 'flex',
        { 'lg:w-[340px] 2xl:w-[400px]': hasRelations },
      ]"
    >
      <div
        v-if="!challengeOpen"
        class="mb-4 flex items-center justify-between px-4 md:px-8 lg:mb-8"
      >
        <h2 class="text-2xl font-bold leading-7 text-foreground-emphasis">
          Relations
        </h2>

        <BaseBadge
          v-if="userStore.user?.organization?.pointEnabled"
          :text="userStore.user?.balance ? userStore.user?.balance : 0"
          size="md"
          icon-type="icon"
          icon-name="star"
        />
      </div>

      <template v-if="hasRelations && !challengeOpen">
        <FieldTabs
          v-model="currentTab"
          :class="[$route?.params.relationId ? 'hidden lg:flex' : 'flex']"
          class="mx-4 mb-2 justify-between md:mx-8 md:justify-start lg:mb-4"
          :tabs="tabs"
          data-cy="relations-tab"
          @update:model-value="router.push({ name: 'relations-empty-state' })"
        />

        <div
          class="flex-1"
          :class="{ 'hidden lg:block': $route?.params.relationId }"
        >
          <template v-if="relations?.length">
            <BaseRelation
              v-for="(relation, relationIndex) in relations"
              :key="relationIndex"
              data-cy="relation"
              :relation="relation"
              :active="
                relation.relationId ===
                parseInt($route?.params.relationId as string)
              "
              @click="onRelationClick(relation)"
            />
          </template>

          <BaseEmptyState
            v-else
            class="flex h-full flex-col items-center justify-center"
            :text="$t(`relationsLayout.empty.${currentTab}`)"
            type="empty-list"
          />
        </div>
      </template>

      <div v-else>
        <BaseEmptyState
          v-if="!challengeOpen"
          class="m-auto my-10"
          :text="$t('relationsLayout.emptyState')"
          type="awaiting-match"
        />

        <div :class="`px-4 md:px-8 ${challengeOpen ? '' : 'mt-20'}`">
          <h4
            v-if="
              !challengeOpen &&
              challenges?.available &&
              challenges?.available.length > 0
            "
            class="text-mgm-txt-md font-medium"
          >
            {{ $t(`relationsLayout.discoverChallenge.title`) }}
          </h4>
          <p
            v-if="
              !challengeOpen &&
              challenges?.available &&
              challenges?.available.length > 0
            "
            class="text-mgm-txt-sm text-foreground-default mb-6"
          >
            {{ $t(`relationsLayout.discoverChallenge.subtitle`) }}
          </p>
          <div
            class="block h-full flex-1"
            :class="{
              'content-start lg:flex lg:flex-wrap':
                challenges?.available.length,
            }"
          >
            <template v-if="challenges?.available.length">
              <Modal
                v-for="(challenge, challengeIndex) in challenges?.available"
                :key="challengeIndex"
                :open="modal === challenge.challengeId"
                @open-focus="(e) => e.preventDefault()"
                content-class="max-w-[90dvw] sm:max-w-[480px]"
                @open-change="
                  (open) => {
                    modal = challenge.challengeId;

                    if (!open) {
                      modal = null;
                    }
                  }
                "
              >
                <template #trigger>
                  <BaseCard
                    class="mb-2 lg:mb-6 lg:mr-6"
                    :title="challenge.name"
                    :description="challenge.description"
                    :img-url="
                      challenge.pictureUrl
                        ? challenge.pictureUrl
                        : mq.lgPlus
                          ? '/img/challenge_lg.png'
                          : '/img/challenge.png'
                    "
                    :points="challenge.points"
                    :badge-status="{
                      points: challenge.points,
                      type: 'challenge',
                    }"
                    :display-points="!challenge.pictureUrl"
                  />
                </template>
                <template #content>
                  <div v-if="currentModalStep === 'start'">
                    <div
                      class="w-12 h-12 bg-[#F1F5F9] rounded-full border-8 border-[#F8FAFC] flex items-center justify-center mb-5"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                          stroke="#5F6C85"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h3
                      class="text-mgm-txt-lg font-semibold text-foreground-emphasis"
                    >
                      {{ challenge.name }}
                    </h3>
                    <BaseBadge
                      class="mt-2"
                      size="md"
                      :text="challenge.points"
                      icon-type="icon"
                      icon-name="star"
                    />
                    <div
                      v-html="challenge.description"
                      class="text-mgm-txt-sm text-foreground-default my-5 overflow-hidden max-h-[40dvh] lg:max-h-auto overflow-y-auto"
                    ></div>
                    <BaseButton
                      size="lg"
                      class="w-full"
                      @click="currentModalStep = 'confirm'"
                      >{{ $t("challenges.available.start.cta") }}</BaseButton
                    >
                  </div>
                  <div v-if="currentModalStep === 'confirm'">
                    <div
                      class="w-12 h-12 bg-[#F1F5F9] rounded-full border-8 border-[#F8FAFC] flex items-center justify-center mb-5"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                          stroke="#5F6C85"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h3
                      class="text-mgm-txt-lg font-semibold text-foreground-emphasis"
                    >
                      {{ $t("challenges.available.confirm.title") }}
                    </h3>
                    <p
                      class="text-mgm-txt-sm text-foreground-default my-5 overflow-hidden"
                    >
                      {{
                        $t("challenges.available.confirm.content", {
                          email: challenge.contactAdminEmail,
                        })
                      }}
                    </p>
                    <div class="flex gap-2">
                      <BaseButton
                        size="lg"
                        class="flex-1"
                        state="scdr"
                        @click="
                          () => {
                            currentModalStep = 'start';
                            modal = null;
                          }
                        "
                        >{{ $t("generic.cancel") }}</BaseButton
                      >
                      <BaseButton
                        size="lg"
                        class="flex-1"
                        @click="
                          () => {
                            startChallenge(challenge.challengeId);
                            currentModalStep = 'end';
                          }
                        "
                        >{{
                          $t("challenges.available.confirm.cta")
                        }}</BaseButton
                      >
                    </div>
                  </div>
                  <div v-if="currentModalStep === 'end'">
                    <div
                      class="w-12 h-12 bg-[#D1FADF] rounded-full border-8 border-[#ECFDF3] flex items-center justify-center mb-5"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                          stroke="#03874C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h3
                      class="text-mgm-txt-lg font-semibold text-foreground-emphasis"
                    >
                      {{ $t("challenges.available.end.title") }}
                    </h3>
                    <ul
                      class="list-disc pl-0 text-mgm-txt-sm text-foreground-default my-4"
                    >
                      <li>
                        {{
                          $t("challenges.available.end.content.first", {
                            email: challenge.contactAdminEmail,
                          })
                        }}
                      </li>
                      <li>
                        {{ $t("challenges.available.end.content.second") }}
                      </li>
                    </ul>
                    <BaseButton
                      size="lg"
                      class="w-full"
                      @click="
                        () => {
                          currentModalStep = 'start';
                          modal = null;
                        }
                      "
                      >{{ $t("generic.close") }}</BaseButton
                    >
                  </div>
                </template>
              </Modal>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasRelations"
      class="w-full border border-border-subtle border-b-transparent border-l-transparent bg-background-default lg:mt-3"
      :class="{
        'hidden lg:block': !$route?.params.relationId,
        'items-start': $route?.params.relationId,
      }"
    >
      <routerView v-if="initialized" />
    </div>
  </div>
</template>
