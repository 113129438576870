<script setup lang="ts">
// NPM
import { onBeforeMount, ref } from "vue";
import { useMq } from "vue3-mq";

// Components
import { BaseCard, BaseEmptyState } from "@magma-app/magma-lapilli";
import BaseRewardChallengeDetails from "@/components/bases/BaseRewardChallengeDetails.vue";

// Types
import type { IReward } from "@/types/IReward";

// Stores
import { useCommonStore } from "@/stores/common";
import { useRewardsStore } from "@/stores/rewards";
import { useUserStore } from "@/stores/user";
const commonStore = useCommonStore();
const rewardsStore = useRewardsStore();
const userStore = useUserStore();

// Plugins
const mq = useMq();

// Reactive variables
const initialized = ref(false);
const rewardDetails = ref<IReward | null>(null);

// Lifecycle hooks
onBeforeMount(async () => {
  await rewardsStore.getRewards();

  initialized.value = true;
});

// Methods
const onCloseRewardDetails = () => {
  rewardsStore.setRewardDetails(false);

  rewardDetails.value = null;
};

const onRewardClick = (reward: IReward) => {
  if (userStore.user) {
    if (!mq.lgPlus) {
      rewardsStore.setRewardDetails(true);
      rewardDetails.value = reward;
    } else if (mq.lgPlus) {
      commonStore.setManageChallengesRewardsModal({
        description: reward.description,
        open: true,
        item: reward,
        role: "reward",
        title: reward.name,
      });
    }
  }
};
</script>

<template>
  <div
    class="block h-full flex-1 px-4 pb-8 md:px-8"
    :class="[
      rewardsStore.allRewards?.length
        ? 'content-start lg:flex lg:flex-wrap'
        : 'flex flex-1',
    ]"
  >
    <template v-if="initialized && rewardsStore.allRewards?.length">
      <template v-if="!rewardDetails">
        <BaseCard
          v-for="(reward, rewardIndex) in rewardsStore.allRewards
            .slice()
            .sort((a, b) => a.points - b.points)"
          :key="rewardIndex"
          class="mb-2 lg:mb-6 lg:mr-6"
          :title="reward.name"
          :img-url="
            reward.pictureUrl != null ? reward.pictureUrl : '/img/reward.png'
          "
          :badge-status="{ points: reward.points }"
          @click="onRewardClick(reward)"
          contain-img
        />
      </template>

      <BaseRewardChallengeDetails
        v-else
        :item="rewardDetails"
        @close="onCloseRewardDetails"
      />
    </template>

    <BaseEmptyState
      v-else
      class="flex flex-1 flex-col items-center justify-center"
      :text="$t('rewardsView.empty')"
      type="empty-list"
    />
  </div>
</template>
