// NPM
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

// Stores
import { useUserStore } from "@/stores/user";
import { useReferrals } from "@/api/referrals/use-referrals";
import { useRoute } from "vue-router";

export function useNavigationItems() {
  // Stores
  const userStore = useUserStore();
  const route = useRoute();
  const { data: referral } = useReferrals(
    route.name === "helpee-report-feedback-view"
  );

  // Plugins
  const { t } = useI18n({ useScope: "global" });

  // Data
  const navigationItems = computed(() => {
    return [
      {
        icon: "users",
        name: "Relations",
        to: {
          name: "relations-empty-state",
        },
        enabled: true,
      },
      {
        icon: "star",
        name: "Challenges",
        to: {
          name: "available-challenges",
        },
        enabled: userStore.user?.organization?.challengeEnabled,
      },
      {
        icon: "gift",
        name: t("generic.rewards"),
        to: {
          name: "rewards",
        },
        enabled: userStore.user?.organization?.rewardEnabled,
      },
      {
        icon: "heart-hand",
        name: t("referral.navigation"),
        to: {
          name: "referral",
        },
        enabled: referral.value?.shouldDisplayReferralCampaign,
      },
      {
        icon: "link",
        name: t("generic.sharingPage"),
        to: {
          name: "sharing",
        },
        enabled: userStore.sharingPageEnabled,
      },
      {
        icon: "toggle",
        name: t("generic.helperStatus"),
        to: {
          name: "helper-status",
        },
        enabled: true,
      },
    ];
  });

  return {
    navigationItems,
  };
}
