import { instances } from "@/services/api";
import { useQuery } from "@tanstack/vue-query";
import type { UserDashboardGetReferralsResponseType } from "@magma-app/magma-types/user-dashboard/referral";

export const useReferrals = (disabled?: boolean) => {
  return useQuery({
    queryKey: ["referrals"] as const,
    queryFn: async () => {
      const response = await instances.userDashboard.get("/referral");
      return response.data as UserDashboardGetReferralsResponseType;
    },
    enabled: disabled !== true,
  });
};
